import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';

import styles from 'styles/Marketing/Legal/Base';

export default class DataProcessingAddendum extends Component {
  render() {
    const orbs = [
      // PURPLE ORBS
      // top right
      {
        width: '600px',
        height: '600px',
        top: '-200px',
        right: 'calc(50% - 775px)',
        background: styles.colorPrimaryPurple,
      },
      // LIGHT BLUE ORB
      {
        width: '75px',
        height: '75px',
        bottom: '-20px',
        right: 'calc(50% - 670px)',
        background: '#18cefe'
      },
      // ORANGE ORB
      // top right
      {
        width: '200px',
        height: '200px',
        top: '-50px',
        left: 'calc(50% - 650px)',
        background: styles.colorSecondaryOrange,
      },
    ];

    return (
      <React.Fragment>
        <Orbs orbs={orbs} blurEffect={false}>
          <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2', 'py-5', 'text-center', 'position-relative')}>
            <h1 className={classnames(styles.h1, 'mb-3', styles.ColorPrimaryWhite)}>Data Processing Addendum</h1>
            <p className={classnames(styles.p1, styles.UpperContent, styles.Tint4, 'pt-3', 'mx-auto')} style={{ maxWidth: '600px' }}>Last updated: October 10, 2023</p>
          </div>
        </Orbs>

        <div className={classnames('mx-auto', 'px-lg-4', 'px-2', 'text-start', 'py-4')} style={{ maxWidth: '800px' }}>
          <p className={classnames(styles.p1)}>This Data Protection Addendum (“Addendum”) is part of the Amply Terms of Service (“Agreement”) between Send Amply Inc. (“Amply”), and the customer (“Customer”), collectively referred to as the “Parties.” This Addendum sets out the terms under which Amply will process Personal Data on behalf of the Customer in compliance with applicable Data Protection Law.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>1. Purpose</h3>
          <p className={classnames(styles.p1)}>The purpose of this Addendum is to ensure that Personal Data is Processed in compliance with applicable laws and to define the responsibilities of Amply and the Customer with respect to such Processing.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>2. Definitions</h3>
          <p className={classnames(styles.p1)}>For the purposes of this Addendum, the following terms and those defined within the body of this Addendum apply.</p>

          <ol className='px-2' type='a'>
            <li><b>Data Protection Law</b> means any applicable law relating to data security, data protection and/or privacy including, without limitation the California Consumer Privacy Act of 2018 (as amended by the California Privacy Rights Act) (“CCPA”) and the EU General Data Protection Regulation 2016/679 (“GDPR”).</li>
            <li><b>Personal Data</b> means any information relating to, describing, or that can reasonably be associated with or linked to an identified or identifiable natural person (“Data Subject”), and which is processed by Amply on behalf of Customer pursuant to the Agreement. An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, identification number, location data, online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that person.</li>
            <li><b>Personal Data Breach</b> means the breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, Personal Data.</li>
            <li><b>Process</b>, <b>Processing</b>, or <b>Processed</b> means any operation performed on Personal Data, including collection, storage, use, or disclosure.</li>
            <li><b>Services</b> means the email delivery and related services provided by Amply under the Agreement.</li>
            <li><b>Standard Contractual Clauses (SCCs)</b> means the clauses adopted by the European Commission for international transfers of Personal Data.</li>
            <li><b>Subprocessor</b> means any third party engaged by Amply to process Personal Data on behalf of the Customer.</li>
          </ol>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>3. Scope</h3>
          <p className={classnames(styles.p1)}></p>
          <ol className='px-2' type='a'>
            <li>This Addendum applies to any Personal Data processed by Amply on behalf of the Customer in connection with the Services. This includes all Personal Data that is provided to Amply by the Customer, processed during the performance of the Services, or collected as a result of the use of the Services, including email addresses, customer lists, and any other Personal Data used for email-related communications.</li>
            <li>The Customer agrees that the processing of Personal Data by Amply under this Addendum is necessary for the performance of the Services and that the Customer is providing all necessary authorizations for Amply to process Personal Data as described herein. The Customer affirms that the processing of Personal Data by Amply complies with the applicable Data Protection Laws and that the Customer has obtained all necessary consents or has other valid legal grounds for the processing of Personal Data.</li>
          </ol>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>4. Obligations of Amply</h3>
          <ol className='px-2' type='a'>
            <li><b>Limitations on Use.</b> Amply will only process Personal Data on behalf of the Customer and in accordance with the Agreement, this Addendum, and any documented instructions agreed upon by the Parties. Amply will not use Personal Data for any purpose other than providing the Services and will not disclose Personal Data to any third parties except as permitted under the terms of the Agreement or as required by law.</li>
            <li><b>Security.</b> Amply will implement appropriate technical and organizational measures to ensure a level of security appropriate to the risks associated with the processing of Personal Data. This includes measures to protect Personal Data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Personal Data. These security measures include encryption, access controls, audit trails, and regular security assessments to ensure that Personal Data remains protected from any unauthorized or unlawful processing.</li>
            <li><b>Confidentiality.</b> Any person authorized to Process Customer Personal Data must contractually agree to maintain the confidentiality of such information or be under an appropriate statutory obligation of confidentiality.</li>
            <li><b>Notice of Certain Events.</b> Amply will promptly notify Customer about: (i) any instruction that, in its view, may violate applicable Data Protection Law; (ii) any complaint, communication, or request received directly by Amply or a Subprocessor from a Data Subject regarding their Personal Data, or from a regulatory authority concerning the Personal Data, provided that Amply will not respond to such request unless otherwise instructed and authorized by Customer or required to do so by applicable law; or (iii) any changes in applicable legislation affecting Amply or a Subprocessor that may significantly impact Amply’s ability to meet its obligations under this Addendum.</li>
            <li><b>Breach Response.</b> Amply will notify Customer without undue delay after becoming aware of a Personal Data Breach, and will take reasonable steps to prevent any further Personal Data Breaches and to mitigate any resulting harm to the Personal Data. Amply will provide prompt cooperation and assistance to Customer in relation to any notifications Customer is required to make as a result of the Personal Data Breach. Additionally, upon written request, Amply will provide reasonable assistance to Customer in relation to any data protection impact assessments or regulatory consultations that Customer is legally required to conduct with respect to the Personal Data.</li>
            <li><b>Data Subject/Supervisory Authority Requests.</b> Amply will provide Customer with reasonable cooperation and assistance regarding any complaint, communication, or request received from a Data Subject or a data protection supervisory authority. Notwithstanding any provisions to the contrary, Amply's obligations under this section will apply only to the extent that Customer does not have direct access to the necessary information through the applicable Amply service.</li>
            <li><b>Audit and Certifications.</b> Amply will maintain appropriate documentation to demonstrate compliance with this Addendum and will, upon reasonable notice, make such documentation available to the Customer for auditing purposes. In addition, Amply will provide certifications as may be necessary to verify its adherence to the data protection measures outlined in this Addendum.</li>
            <li><b>Return or Disposal.</b> The Parties agree that upon termination of the Services in relation to Personal Data, Amply shall, and shall require all Subprocessors to, at the Customer's discretion, either return all Personal Data and any copies thereof to the Customer or securely destroy all Personal Data and certify to the Customer that this has been done, unless prohibited by applicable law.</li>
          </ol>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>5. Subprocessors</h3>
          <ol className='px-2' type='a'>
            <li>Customer hereby generally authorizes Amply to appoint Subprocessors for the purpose of processing Personal Data in accordance with the Agreement.</li>
            <li>Upon Customer's request, or as otherwise required by applicable Data Protection Laws, Amply shall make available information about Subprocessors that, to Amply's actual knowledge, will process Personal Data. This information will be provided by Amply online through the following website: <a href='https://sendamply.com/legal/subprocessors'>https://sendamply.com/legal/subprocessors</a> and will be updated by Amply periodically.</li>
            <li>Amply will notify Customer of any new Subprocessor that, to Amply’s actual knowledge, will process Personal Data and is engaged during the term of the Agreement. This notification may be made by updating the URL, Customer portal, or account information, or by emailing Customer before the new Subprocessor begins processing Personal Data. If Customer can reasonably demonstrate that the appointment of a new Subprocessor will materially affect its ability to comply with applicable Data Protection Laws, Customer must promptly notify Amply in writing within fifteen (15) business days, explaining the reasonable grounds for objection to the use of the new Subprocessor. Upon receipt of Customer’s objection, Amply and Customer will collaborate without undue delay to find an alternative solution. If a mutually acceptable alternative cannot be agreed upon, Customer may terminate the Agreement with respect to those Services that cannot be provided without the use of the new Subprocessor. Unless prohibited by applicable Data Protection Laws, in the event of such termination by Customer, Amply may retain or require payment for the terminated Services through the end of the current contract term.</li>
            <li>In the event Amply engages Subprocessors in connection with the Services, Amply shall impose obligations on such Subprocessors that are the same as, or similar to, those in this Addendum, or other obligations required by applicable Data Protection Laws. Amply shall remain fully liable to Customer for the acts or omissions of these Subprocessors, as if they were the acts or omissions of Amply.</li>
          </ol>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>6. International Transfers of Personal Data</h3>
          <p className={classnames(styles.p1)}>Any transfers (whether between Customer and Amply, or Amply and a Subprocessor) of Personal Data protected by the GDPR and/or the UK GDPR to a country outside the European Economic Area (“EEA”) or the United Kingdom (“UK”) that does not provide adequate protection for such Personal Data shall be subject to the applicable Standard Contractual Clauses, which are incorporated herein by reference. In the event of any inconsistencies between the provisions of the Standard Contractual Clauses and this Addendum or any other agreements between the Parties, the Standard Contractual Clauses shall take precedence, but only with respect to Personal Data transferred outside the EEA or UK. The information set forth in Schedule 1 constitutes the information required to be included in the schedules and appendices to the Standard Contractual Clauses, and the Parties’ signatures to this Addendum are deemed to also constitute the signature of the Standard Contractual Clauses, to the extent such execution is required.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>7. CCPA Compliance</h3>
          <p className={classnames(styles.p1)}>To the extent applicable and pursuant to the CCPA, with respect to “personal information” as defined by the CCPA that Amply may process in connection with the performance of the Services, Amply agrees and certifies that it will not:</p>
          <ol className='px-2' type='a'>
            <li>Sell, rent, release, disclose, disseminate, make available, transfer, or otherwise communicate orally, in writing, or by electronic or other means, such personal information to another business or third party for monetary or other valuable consideration; or</li>
            <li>Retain, use, disclose, collect, sell, use, or otherwise process such personal information (i) for any purpose other than for the specific purpose of, and as necessary for, performing Services for Customer under the Agreement, or (ii) as otherwise permitted by the CCPA.</li>
          </ol>
          <p className={classnames(styles.p1)}>Amply further agrees to cooperate and assist Customer in fulfilling and complying with any consumer rights request under the CCPA.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>8. Legal Requests</h3>
          <p className={classnames(styles.p1)}>Unless prohibited by applicable law, in the event that Amply is required by law, court order, warrant, subpoena, or other legal process (“Legal Request”) to disclose any Personal Data to any person or entity other than Customer (including, without limitation, pursuant to any government surveillance order of which Amply is aware), Amply shall promptly notify Customer and provide reasonable assistance to Customer, at Customer’s cost, to enable Customer to respond to, object to, or challenge any such Legal Request. Amply shall not disclose Personal Data pursuant to a Legal Request unless required to do so by applicable law and has otherwise complied with the obligations in this Section.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>9. Miscellaneous</h3>
          <p className={classnames(styles.p1)}>The Parties acknowledge and agree that the limitations and exclusions of liability set forth in the Agreement shall also apply to this Addendum.</p>
          <p className={classnames(styles.p1)}>Upon termination of the Agreement, Amply’s relevant obligations under this Addendum shall survive to the extent Amply continues to process Personal Data. In the event of a conflict between this Addendum and the Agreement, the terms of this Addendum shall prevail.</p>



          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1', 'text-center')}>Schedule 1</h3>
          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mb-3', 'text-center')}>Details of the Processing of Personal Data</h5>

          <ol className='px-2'>
            <li>
              <p className={classnames(styles.p1, 'mb-1')}><b>The Nature and Purpose of the Processing:</b></p>
              <p className={classnames(styles.p1)}>Amply’s performance of its Services under the Agreement.</p>
            </li>
            <li>
              <p className={classnames(styles.p1, 'mb-1')}><b>The Duration of the Processing:</b></p>
              <p className={classnames(styles.p1)}>The duration of the Processing will be for as long as Amply performs the Services for Customer, or processes Personal Data received from Customer, or in connection with the provision of the Services under the Agreement.</p>
            </li>
            <li>
              <p className={classnames(styles.p1, 'mb-1')}><b>The Types of Personal Data:</b></p>
              <p className={classnames(styles.p1)}>Personal Data processed related to the following categories: all categories of data necessary for the provision of the Services by Amply on behalf of Customer. Personal Data processed does not include special categories of Personal Data.</p>
            </li>
            <li>
              <p className={classnames(styles.p1, 'mb-1')}><b>The Categories of Data Subjects:</b></p>
              <p className={classnames(styles.p1)}>Personal Data Processed relating to the following categories of Data Subjects: Employees and other personnel of Customer.</p>
            </li>
            <li>
              <p className={classnames(styles.p1, 'mb-1')}><b>Processing Instructions:</b></p>
              <p className={classnames(styles.p1)}>Personal Data processed shall be subject to the following processing activities, in addition to any activities set forth in the Agreement: processing by Amply (or Subprocessors) in relation to the provision of Services to Customer, in accordance with the terms of this Addendum and the Agreement.</p>
            </li>
            <li>
              <p className={classnames(styles.p1, 'mb-1')}><b>Obligations and Rights of Customer:</b></p>
              <p className={classnames(styles.p1)}>The obligations and rights of Customer are outlined in the Agreement and this Addendum.</p>
            </li>
            <li>
              <p className={classnames(styles.p1, 'mb-1')}><b>Technical and Organizational Measures:</b></p>
              <p className={classnames(styles.p1)}>Amply implements and maintains industry-standard technical and organizational measures to safeguard the security of Personal Data processed in connection with its Services. These measures include, but are not limited to, the following (as appropriate to the nature of the Personal Data processed):
                <ul className='px-2 mt-1'>
                  <li>Firewall protections</li>
                  <li>Access controls</li>
                  <li>Protections against viruses and malware</li>
                  <li>Implementation of security settings</li>
                  <li>Regular updates to address bugs and security vulnerabilities</li>
                  <li>Routine data backups</li>
                </ul>
              </p>
            </li>
          </ol>
        </div>
      </React.Fragment>
    );
  }
}
