import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';

import styles from 'styles/Marketing/Legal/Base';

export default class Privacy extends Component {
  render() {
    const orbs = [
      // PURPLE ORBS
      // top right
      { 
        width: '600px',
        height: '600px',
        top: '-200px',
        right: 'calc(50% - 775px)',
        background: styles.colorPrimaryPurple,
      },
      // LIGHT BLUE ORB
      {
        width: '75px',
        height: '75px',
        bottom: '-20px',
        right: 'calc(50% - 670px)',
        background: '#18cefe'
      },
      // ORANGE ORB
      // top right
      {
        width: '200px',
        height: '200px',
        top: '-50px',
        left: 'calc(50% - 650px)',
        background: styles.colorSecondaryOrange,
      },
    ];

    return (
      <React.Fragment>
        <Orbs orbs={orbs} blurEffect={false}>
          <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2', 'py-5', 'text-center', 'position-relative')}>
            <h1 className={classnames(styles.h1, 'mb-3', styles.ColorPrimaryWhite)}>Privacy Policy</h1>
            <p className={classnames(styles.p1, styles.UpperContent, styles.Tint4, 'pt-3', 'mx-auto')} style={{ maxWidth: '600px' }}>Last updated: July 1, 2022</p>
          </div>
        </Orbs>

        <div className={classnames('mx-auto', 'px-lg-4', 'px-2', 'text-start', 'py-4')} style={{ maxWidth: '800px' }}>
          <p className={classnames(styles.p1)}>This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can update, manage, export, and delete your information.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Information Amply Collects and Why</h3>

          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Information from web browsers</h5>
          <p className={classnames(styles.p1)}>We collect the same basic information that most websites collect. We use common internet technologies, such as cookies and web server logs. This is information we collect from everybody, whether they have an account or not.</p>
          <p className={classnames(styles.p1)}>This information includes the visitor’s browser type, referring site, the date and time of each visitor request. We also collect potentially personally-identifying information like Internet Protocol (IP) addresses.</p>
          <p className={classnames(styles.p1)}>We collect this information to better understand how our website visitors use Amply, and to monitor and protect the security of the website.</p>

          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Information from users with accounts</h5>
          <p className={classnames(styles.p1)}>If you create an account, we require basic information at the time of account creation. You will create a password and we will also ask for you email address. We limit what information we collect to what is necessary to operate Amply. Your email and password is used to authenticate your identity.</p>
          <p className={classnames(styles.p1)}>"User Personal Information" is any information about one of our users which could, alone or together with other information, personally identify him or her. User Personal Information includes Personal Data as defined in the General Data Protection Regulation.</p>


          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Information Amply Does Not Collect</h3>
          <p className={classnames(styles.p1)}>If you are a child under the age of 13, you may not have an account on Amply. Amply does not knowingly collect information from or direct any of our content specifically to children under 13. If we learn or have reason to suspect that you are a user who is under the age of 13, we will terminate your account.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>How We Share the Information We Collect</h3>
          <p className={classnames(styles.p1)}>We do not share, sell, rent, or trade User Personal Information with third parties for their commercial purposes, except where you have specifically told us to.</p>
          <p className={classnames(styles.p1)}>We do share User Personal Information with a limited number of third party vendors who process it on our behalf to provide or improve our service, and who have agreed to privacy restrictions similar to our own Privacy Policy. Our vendors perform services such as payment processing, customer support ticketing, network data transmission, and other similar services.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>How We Share the Information We Collect</h3>
          <p className={classnames(styles.p1)}>In addition to your account page, you may contact Amply support at <a href='/contact'>https://sendamply.com/contact</a>.</p>

          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Data retention</h5>
          <p className={classnames(styles.p1)}>Amply will retain User Personal Information for as long as your account is active or as needed to provide our services.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Cookies and Tracking</h3>
          <p className={classnames(styles.p1)}>Amply uses cookies to ensure the funcionality of our service. We use cookies to keep you logged in and and to identify a device for security reasons. By using our website, you agree that we can place these types of cookies on your device.</p>
          <p className={classnames(styles.p1)}>We use the following cookies:</p>

          <table className={classnames(styles.Table, 'mb-3')}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Purpose</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>_amply_session</td>
                <td>This stores information about your current session and keeps you logged in to Amply.</td>
              </tr>
              <tr>
                <td>_amply_uuid</td>
                <td>This is a unique identifier to identify your account.</td>
              </tr>
              <tr>
                <td>__stripe_mid, __stripe_sid</td>
                <td>These are used by our payment processor, Stripe.</td>
              </tr>
            </tbody>
          </table>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>How Amply Secures Your Information</h3>
          <p className={classnames(styles.p1)}>Amply services are built with strong security features that continuously protect your information.</p>

          <ul className='mb-2 px-2'>
            <li>We use encryption to keep your data private while in transit.</li>
            <li>We use up-to-date software to deliver our services and use industry recognized and vetted frameworks.</li>
            <li>We review our information collection, storage, and processing practices, including physical security measures, to prevent unauthorized access to our systems.</li>
            <li>We restrict access to personal information to Amply employees, contractors, and agents who need that information in order to process it. Anyone with this access is subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.</li>
          </ul>

          <p className={classnames(styles.p1)}>In the event of a data breach that affects your User Personal Information, we will act promptly to mitigate the impact of a breach and notify any affected users without undue delay.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Compelled Disclosure</h3>
          <p className={classnames(styles.p1)}>Amply may disclose personally-identifying information or other information we collect about you to law enforcement in response to a valid subpoena, court order, warrant, or similar government order, or when we believe in good faith that disclosure is reasonably necessary to protect our property or rights, or those of third parties or the public at large.</p>
          <p className={classnames(styles.p1)}>In complying with court orders and similar legal processes, Amply strives for transparency. When permitted, we will make a reasonable effort to notify users of any disclosure of their information, unless we are prohibited by law or court order from doing so, or in rare, exigent circumstances.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Changes to this Policy</h3>
          <p className={classnames(styles.p1)}>We change this Privacy Policy from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. We always indicate the date the last changes were published and we offer access to archived versions for your review. If changes are significant, we’ll provide a more prominent notice (including, for certain services, email notification of Privacy Policy changes).</p>
        </div>
      </React.Fragment>
    );
  }
}
