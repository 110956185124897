import React, { Component } from 'react';

import Content from 'components/Marketing/Content';
import Show from 'components/Marketing/Legal/DataProcessingAddendum';

export default class DataProcessingAddendum extends Component {
  render() {
    return (
        <Content>
          <Show />
        </Content>
    );
  }
}
