import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';

import styles from 'styles/Marketing/Legal/Base';

export default class Subprocessors extends Component {
  render() {
    const orbs = [
      // PURPLE ORBS
      // top right
      { 
        width: '600px',
        height: '600px',
        top: '-200px',
        right: 'calc(50% - 775px)',
        background: styles.colorPrimaryPurple,
      },
      // LIGHT BLUE ORB
      {
        width: '75px',
        height: '75px',
        bottom: '-20px',
        right: 'calc(50% - 670px)',
        background: '#18cefe'
      },
      // ORANGE ORB
      // top right
      {
        width: '200px',
        height: '200px',
        top: '-50px',
        left: 'calc(50% - 650px)',
        background: styles.colorSecondaryOrange,
      },
    ];

    return (
      <React.Fragment>
        <Orbs orbs={orbs} blurEffect={false}>
          <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2', 'py-5', 'text-center', 'position-relative')}>
            <h1 className={classnames(styles.h1, 'mb-3', styles.ColorPrimaryWhite)}>Amply Subprocessors</h1>
            <p className={classnames(styles.p1, styles.UpperContent, styles.ColorPrimaryWhite, 'pt-3', 'mx-auto')} style={{ maxWidth: '600px' }}>Amply engages the third-party entities (each, a "Subprocessor") in the tables below to perform limited activities related to infrastructure services, and to help us provide customer support and notifications. The tables show what activity each Subprocessor performs and explains the limited processing of Customer Data the Subprocessor is authorized to perform. Prior to engaging any third party Subprocessor, Amply performs diligence to evaluate their privacy, security and confidentiality practices.</p>
          </div>
        </Orbs>

        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2', 'text-center', 'py-5', 'pb-0')}>
          <h2 className={classnames(styles.h2, styles.ColorPrimaryPurple, 'mb-3')}>Infrastructure Subprocessors</h2>
          <table className={classnames(styles.Table, 'mb-5')}>
            <thead>
              <tr>
                <th>Subprocessor</th>
                <th>Purpose</th>
                <th>Data Location</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Amazon Web Services, Inc.</td>
                <td>Hosting & Infrastructure</td>
                <td>United States</td>
              </tr>
            </tbody>
          </table>

          <h2 className={classnames(styles.h2, styles.ColorPrimaryPurple, 'mb-3')}>Other Subprocessors</h2>
          <table className={classnames(styles.Table, 'mb-5')}>
            <thead>
              <tr>
                <th>Subprocessor</th>
                <th>Purpose</th>
                <th>Data Location</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Google, Inc.</td>
                <td>Cloud-based Email Service Provider, Product Analytics</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>HubSpot, Inc.</td>
                <td>Customer Relationship Management</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Rollbar, Inc.</td>
                <td>Error Logging & Tracking</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Slack Technologies, Inc.</td>
                <td>Messaging Services</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Stripe, Inc.</td>
                <td>Payment Processor</td>
                <td>United States</td>
              </tr>
            </tbody>
          </table>

        </div>
      </React.Fragment>
    );
  }
}
