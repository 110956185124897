import React, { Component } from 'react';
import classnames from 'classnames';

import slaUptime from './sla_uptime_percent.png';

import Orbs from 'components/Util/Misc/Orbs';

import styles from 'styles/Marketing/Legal/Base';

export default class ServiceLevelAgreement extends Component {
  render() {
    const orbs = [
      // PURPLE ORBS
      // top right
      {
        width: '600px',
        height: '600px',
        top: '-200px',
        right: 'calc(50% - 775px)',
        background: styles.colorPrimaryPurple,
      },
      // LIGHT BLUE ORB
      {
        width: '75px',
        height: '75px',
        bottom: '-20px',
        right: 'calc(50% - 670px)',
        background: '#18cefe'
      },
      // ORANGE ORB
      // top right
      {
        width: '200px',
        height: '200px',
        top: '-50px',
        left: 'calc(50% - 650px)',
        background: styles.colorSecondaryOrange,
      },
    ];

    return (
      <React.Fragment>
        <Orbs orbs={orbs} blurEffect={false}>
          <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2', 'py-5', 'text-center', 'position-relative')}>
            <h1 className={classnames(styles.h1, 'mb-3', styles.ColorPrimaryWhite)}>Service Level Agreement</h1>
            <p className={classnames(styles.p1, styles.UpperContent, styles.Tint4, 'pt-3', 'mx-auto')} style={{ maxWidth: '600px' }}>Last updated: October 10, 2023</p>
          </div>
        </Orbs>

        <div className={classnames('mx-auto', 'px-lg-4', 'px-2', 'text-start', 'py-4')} style={{ maxWidth: '800px' }}>
          <p className={classnames(styles.p1)}>During the Term of the Agreement under which Send Amply Inc. ("Amply") has agreed to provide the Covered Services to Customer (as applicable, the "Agreement"), the Covered Services will provide a Monthly Uptime Percentage of at least 99.99% (the "Service Level Objective" or "SLO"). This SLA outlines Amply’s commitments to service uptime and the remedies available to Customer if these commitments are not met.</p>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Definitions</h3>
          <p className={classnames(styles.p1)}>The following definitions apply to the Service Level Agreement:</p>

          <ul className='px-2'>
            <li>A <b>Covered Service</b> is any paid service or product provided by Amply.</li>
            <li>A <b>Valid Request</b> means any HTTP request matching Amply documentation that would normally result in a non-error response.</li>
            <li><b>Error Rate</b> means the number of Valid Requests that result in a response with HTTP status 5xx, divided by the total number of Valid Requests in a given period. Repeated identical responses do not count toward Error Rate.</li>
            <li><b>Downtime</b> means more than ten percent Error Rate for a Covered Service.</li>
            <li><b>Downtime Minutes</b> means a period of one minute of Downtime for a Covered Service, where the total Valid Requests meets or exceeds the Service Request Threshold. Partial minutes or Intermittent Downtime for a period of less than one minute will not be counted towards as a Downtime Period.</li>
            <li><b>Monthly Uptime Percentage</b> is the percentage of total time in a calendar month that the Covered Service is available, calculated as: <img className='my-2 w-100' style={{maxWidth: '600px'}} src={slaUptime} /></li>
            <li><b>Service Credits</b> means a dollar or percentage credit applied to future invoices for the Covered Service when the Monthly Uptime Percentage falls below the ranges set forth in the following table:
          <table className={classnames(styles.Table, 'my-1')}>
            <thead>
              <tr>
                <th>Monthly Uptime Percentage</th>
                <th>Service Level Credit Percentage</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>&lt; 99.99% and >= 99.9%</td>
                <td>10%</td>
              </tr>
              <tr>
                <td>&lt; 99.9%</td>
                <td>20%</td>
              </tr>
            </tbody>
          </table>
</li>
            <li><b>Request Threshold</b> means the minimum number of Valid Requests required to qualify a Downtime Period for a Service Credit. In the event a Covered Service is not specified in the following table, the Service Request Threshold will default to one hundred Valid Requests per Downtime Period.
              <table className={classnames(styles.Table, 'my-1')}>
                <thead>
                  <tr>
                    <th>Covered Service</th>
                    <th>Valid Requests per Downtime Period</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>REST API</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>SMTP Relay</td>
                    <td>100</td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ul>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Remedies for Downtime</h3>
          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Customers must request Service Credits</h5>
          <p className={classnames(styles.p1)}>To request a Service Credit, Customer must submit a written claim to Amply via support within <b>30 days</b> of the end of the affected month. The claim must include:</p>
          <ul className='px-2'>
            <li>A detailed description of the incident(s).</li>
            <li>Dates and times of the Downtime.</li>
            <li>Logs or other evidence substantiating the claim.</li>
          </ul>
          <p className={classnames(styles.p1)}>Amply will review all claims and notify the Customer of its decision within <b>30 days</b> of receipt.</p>
          <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mt-3', 'mb-1')}>Limitations on Service Credits</h5>
          <ul className='px-2'>
            <li>Service Credits apply only toward future payments for the Covered Service and have no cash value.</li>
            <li>Service Credits are the sole and exclusive remedy for any failure by Amply to meet the uptime commitments in this SLA.</li>
          </ul>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Exclusions</h3>
          <p className={classnames(styles.p1)}>This SLA does not apply to any performance issues caused by:</p>
          <ul className='px-2'>
            <li><b>Scheduled Maintenance</b>: Periods announced at least 24 hours in advance.</li>
            <li><b>Force Majeure Events</b>: Compliance with government requests, natural disasters, acts of war, or Internet disturbances.</li>
            <li><b>Emergency maintenance</b>: Deemed necessary by Amply to protect the Customer or ensure the integrity of the Covered Service, including but not limited to actions such as security patching or mitigating common vulnerabilities and exposures (CVEs).</li>
          </ul>

          <h3 className={classnames(styles.h3, styles.ColorPrimaryPurple, 'mt-4', 'mb-1')}>Updates</h3>
          <p className={classnames(styles.p1)}>Amply may update this SLA from time to time. The then-current version of this SLA is available at <a href='https://sendamply.com/legal/service-level-agreement'>https://sendamply.com/legal/service-level-agreement</a>.</p>
        </div>
      </React.Fragment>
    );
  }
}
